<template>
  <div>
    <title>About Us</title>
    <v-container
        fluid
        class="pa-0 ma-0 team-header mt-md-10">
      <v-container class="px-xl-16 text-center white--text">
        <v-row class="pt-16 mb-5">
          <img src="/images/logo/logo-white.svg"
                 alt="LearningPlus Logo"
                 height="40"
                 width="200"
                 class="mb-2 mt-6 contain pp-center"
          />
        </v-row>
        <div class="pp-hr pp-center"></div>
        <h1 class="text-h4 text-sm-h3 text-xl-h1 font-weight-bold">
          We power better training experiences
        </h1>
      </v-container>
    </v-container>

    <v-container class="my-10 text-center">
      <h2 class="primary--text text-h4 text-md-h2 font-weight-bold mb-5">
        Meet the Team
      </h2>

      <p>
        Our user-focused LMS and industry-leading expertise enables businesses to deliver impactful training that fuels employee, partner and customer success. Our LMS is used in industries as wide-ranging and diverse as food production, construction, manufacturing, recruitment, hospitality and care. We help organisations meet regulatory requirements for the delivery, certification and management of training.
      </p>

      <p>
        Since LearningPlus began in 2011 we have supported
        <strong>
          100,000+ learners, 500+ employer customer sites and delivered 300,000+ courses.
        </strong>
      </p>
    </v-container>

    <v-container>
      <v-row class="justify-center">
        <div
            v-for="(info, i) in companyStructure"
            :key="i"
        >
          <template>
            <v-hover v-slot="{ hover }">
              <v-card
                  class="rounded-xl transparent white--text mx-5 z-10"
                  flat
                  width="350"
                  height="550"
              >
                <v-img
                    :aspect-ratio="1"
                    :src="info.image"
                    class="rounded-xl elevation-5 pp-scale-1"
                    height="450"
                >
                  <v-expand-transition>
                    <div
                        v-if="hover"
                        class="d-flex transition-fast-in-fast-out red-team-bg"
                    >
                      {{ info.description }}
                    </div>
                  </v-expand-transition>
                </v-img>
                <v-card-title class="text-h4 font-weight-bold pt-5 mt-0 pb-6">
                  {{ info.name }}
                </v-card-title>

                <v-card-subtitle class="white--text pp-team-title">
                  {{ info.title }}
                </v-card-subtitle>
              </v-card>
            </v-hover>
          </template>

          <v-container fluid
                       class="pa-0 ma-0 pp-top primary"
          >
            <div class="primary-container pa-xs-0">
            </div>
          </v-container>
        </div>
      </v-row>
      <v-divider />
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "About",
  metaInfo: {
    title: 'About LearningPlus',
    meta:[
      { description: 'Our user-focused LMS and industry-leading expertise enables businesses to deliver impactful training that fuels employee, partner and customer success. Our LMS is used in industries as wide-ranging and diverse as food production, construction, manufacturing, recruitment, hospitality and care. We help organisations meet regulatory requirements for the delivery, certification and management of training.' },
      { image: 'https://www.learningplus.co.uk/images/team/team.jpg' },
    ],
  },
  computed: {
    ...mapGetters(['companyStructure']),
  },
};
</script>

<style scoped>
.team-header {
  background-image:
      linear-gradient(to bottom, rgb(147, 0, 0), rgba(147, 0, 0, 0)),
      url("/images/team/team.jpg");
  background-size: cover;
  background-position: center;
  height: 60vh;
}

.red-team-bg {
  background-image: url("/images/team/red-team-bg.jpg");
  background-size: cover;
  background-position: center;
  color: white;
  padding: 10px;
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.pp-team-title {
  font-weight: 500;
  font-size: larger;
}

.primary-container {
  height:200px;
  padding: 20px;
}

.pp-top {
  top: -150px !important;
  position: relative;
}

.z-10 {
  z-index: 10
}
</style>
